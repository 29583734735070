import { getSuppliersByUser, postCategoryFour } from '@/api/dataentry-api-generated';
import { defineComponent } from '@vue/runtime-core';
export default defineComponent({
    name: 'CategoryFourFileUploadComponent',
    props: {
        propSelected: Array,
        propCreateNew: Boolean,
        propDuplicateRecord: Boolean,
        propId: String
    },
    data: function () {
        return {
            filter: '',
            effectiveDates: '',
            createNew: this.propCreateNew,
            duplicateRecord: this.propDuplicateRecord,
            id: this.propId,
            loading: false,
            suppliers: [],
            item: {},
            supplierOptions: [{
                    label: '',
                    value: ''
                }],
            supplierDisplay: {
                label: '',
                value: ''
            },
            fileName: '',
            fileContent: '',
            noOfExpectedColumns: 6,
            supplierColumn: [],
            transportLegColumn: [],
            expenditureSupplierNameColumn: [],
            totalSpend9MonthsActualsColumn: [],
            totalSpend12MonthTotalColumn: [],
            transportSubTypeColumn: []
        };
    },
    computed: {
        uuid() {
            return this.$route.params.uuid;
        },
        selectedItems() {
            return this.propSelected;
        },
        buttonLabel() {
            return 'SAVE';
        }
    },
    created() {
        this.getSuppliers();
    },
    methods: {
        saveItem() {
            if (this.createNew) {
                this.createItem();
            }
        },
        handleCSVFileUpload(event) {
            // Get the first file
            const file = event.target.files[0];
            if (file !== null) {
                const reader = new FileReader();
                // Read the file content as text
                reader.readAsText(file);
                reader.onload = (event) => {
                    // Store file content
                    this.fileContent = event.target?.result;
                };
            }
        },
        async parseCSVFile() {
            // Split content by new lines and remove header from first row
            const rows = this.fileContent.split('\n');
            rows.shift();
            rows.forEach((line) => {
                // Skip empty lines
                if (line.trim() !== '') {
                    const columns = line.split(',');
                    // Ensure the row has the expected number of columns
                    if (columns.length === this.noOfExpectedColumns) {
                        // Separate the file content into the Category 4 columns
                        this.supplierColumn.push(columns[0].trim());
                        this.transportLegColumn.push(columns[1].trim());
                        this.expenditureSupplierNameColumn.push(columns[2].trim());
                        this.totalSpend9MonthsActualsColumn.push(parseFloat(columns[3].trim()));
                        this.totalSpend12MonthTotalColumn.push(parseFloat(columns[4].trim()));
                        this.transportSubTypeColumn.push(columns[5].trim());
                    }
                }
            });
            // Return how many rows the CSV file had
            return rows.length;
        },
        async createUploadedRecords(rowsParsed) {
            // Send a separate post request for each row
            for (let i = 0; i < rowsParsed; i++) {
                const req = this.item;
                req.supplier = this.supplierDisplay.value;
                req.transportLeg = this.transportLegColumn[i];
                req.expenditureSupplierName = this.expenditureSupplierNameColumn[i];
                req.totalSpend9MonthsActuals = this.totalSpend9MonthsActualsColumn[i];
                req.totalSpend12MonthTotal = this.totalSpend12MonthTotalColumn[i];
                req.transportSubType = this.transportSubTypeColumn[i];
                await postCategoryFour(req);
            }
        },
        async createItem() {
            const valid = await this.$refs.form.validate();
            if (valid) {
                this.loading = true;
                try {
                    const rowsParsed = await this.parseCSVFile();
                    await this.createUploadedRecords(rowsParsed);
                    this.$log.addMessage('Successfully created [' + rowsParsed + '] new Category 4 records');
                    this.$emit('refresh');
                    this.closeDialog();
                }
                catch (error) {
                    const errorObj = error;
                    if (errorObj.message.includes('ResourceAlreadyExistsException')) {
                        const uuid = errorObj.message.substring(31, errorObj.message.length);
                        const msg = `A record with the same Category 4 details already exists: <a href="#/category-four/${uuid}">VIEW</a>`;
                        const errorMsg = {
                            type: 'warning',
                            textColor: 'black',
                            timeout: 5000,
                            position: 'top',
                            message: msg,
                            html: true
                        };
                        this.$log.addCustomMessage(errorMsg);
                    }
                    else {
                        this.$log.addError(error);
                    }
                    this.loading = false;
                }
            }
        },
        closeDialog() {
            if (this.createNew) {
                this.$emit('show-hide-dialog');
            }
            else {
                this.goBack();
            }
        },
        goBack() {
            this.$router.push({ name: 'Cat. 4 - Upstream Transportation & Distribution Records' });
        },
        async getSuppliers() {
            this.loading = true;
            try {
                const result = await getSuppliersByUser({ pageSize: 1000000, deleted: false });
                this.suppliers = result.items;
                this.supplierOptions = this.getSupplierOptions();
                this.loading = false;
            }
            catch (error) {
                this.$log.addError(error);
                this.loading = false;
            }
        },
        getSupplierOptions() {
            return this.suppliers.map(i => {
                return {
                    label: i.supplierLabel,
                    value: i.uuid
                };
            });
        },
        filterOptions(val, update) {
            if (val === '') {
                update(() => {
                    // No filter - display default list
                    this.supplierOptions = this.getSupplierOptions();
                });
                return;
            }
            update(() => {
                const needle = val.toLowerCase();
                this.supplierOptions = this.getSupplierOptions().filter(v => v.label.toLowerCase().indexOf(needle) > -1);
            });
        }
    }
});
