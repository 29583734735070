import { render } from "./CategoryFourRecords.vue?vue&type=template&id=72c3b286&scoped=true"
import script from "./CategoryFourRecords.vue?vue&type=script&lang=ts"
export * from "./CategoryFourRecords.vue?vue&type=script&lang=ts"

import "./CategoryFourRecords.vue?vue&type=style&index=0&id=72c3b286&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-72c3b286"

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QBtn,QTable,QInput,QIcon,QTr,QTh,QTd,QSpace});
