import { defineComponent } from 'vue';
import CategoryFourRecords from '@/views/CategoryFour/CategoryFourRecords.vue';
export default defineComponent({
    name: 'CategoryFourLayout',
    components: { CategoryFourRecords },
    data() {
        return {
            loading: false,
            tab: 'Cat. 4 - Upstream Transportation & Distribution Records'
        };
    }
});
