import { render } from "./CategoryFourDialog.vue?vue&type=template&id=2baf9624&scoped=true"
import script from "./CategoryFourDialog.vue?vue&type=script&lang=ts"
export * from "./CategoryFourDialog.vue?vue&type=script&lang=ts"

import "./CategoryFourDialog.vue?vue&type=style&index=0&id=2baf9624&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-2baf9624"

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QDialog});
