import { defineComponent } from 'vue';
import CategoryFourComponent from '@/components/CategoryFour/CategoryFourComponent.vue';
export default defineComponent({
    name: 'CategoryFourDialog',
    props: {
        propSelected: Array
    },
    components: { CategoryFourComponent },
    data: function () {
        return {
            show: false
        };
    },
    methods: {
        refresh() {
            this.$emit('refresh');
        },
        showHideDialog() {
            this.show = !this.show;
        },
        createNew() {
            this.show = true;
        }
    }
});
